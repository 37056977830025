import { Flex, HTMLChakraProps, Image, useToken } from '@chakra-ui/react'
import * as React from 'react'
import GMCLogSq from '../../assets/images/upgrad_logo_red.png'

export const Logo = (props: HTMLChakraProps<'div'> & { iconColor?: string }) => {
  const { iconColor = 'currentColor', ...rest } = props
  const color = useToken('colors', iconColor)
  return (
    <Flex
      alignItems="center"
      {...rest}
    >
      <Image
        src={GMCLogSq} 
        h="inherit"
        mr={2}
        color={color}
      />
      {/* <Text
        fontSize="inherit"
        color="#32d4e1"
        fontWeight="700"
      >
        Give My Certificate
      </Text> */}
    </Flex>
  )
}
